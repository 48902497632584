import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import React, { useRef } from "react";
import StripeCheckOut from "../common/payment/StripeCheckOut";
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);

const StripePaymentModal = (props) => {
  const {
    paymentModal,
    paymentModalClose,
    clientSecret,
    customerSessionClientSecret,
    paymentSuccess,
  } = props;
  const stripeCheckOutRef = useRef();

  const handleClose = () => {
    if (stripeCheckOutRef.current) {
      stripeCheckOutRef.current.resetStripeElement();
    }
    paymentModalClose();
  };
  const loader = "auto";
  const options = {
    clientSecret,
    customerSessionClientSecret,
    appearance: {
      theme: "night",
      labels: "floating",
      variables: {
        borderRadius: "8px",
        colorPrimary: "#C09117",
        colorBackground: "#0A2540",
        accessibleColorOnColorPrimary: "#1A1B25",
        colorText: "black",
        colorTextSecondary: "black",
        colorTextPlaceholder: "rgba(255, 255, 255, 0.80)",
        tabIconColor: "white",
        logoColor: "dark",
      },
      rules: {
        ".Input, .Block": {
          backgroundColor: "white",
          border: "1.5px solid rgba(248, 248, 248, 0.28)",
        },
      },
    },
    loader,
  };

  return (
    <Modal
      centered
      open={paymentModal}
      onOk={() => handleClose()}
      onCancel={() => handleClose()}
      footer={""}
      className="add_card_modal_wrapper"
    >
      <div className="card_content">
        <h5 className="starting_heading">Payment</h5>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <StripeCheckOut paymentSuccess={paymentSuccess}  ref={stripeCheckOutRef} />
          </Elements>
        )}
      </div>
    </Modal>
  );
};

export default StripePaymentModal;
