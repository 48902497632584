import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ToastMe from "../ToastMe";

const StripeCheckOut = forwardRef(({ paymentSuccess }, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    resetStripeElement() {
      const paymentElement = elements.getElement(PaymentElement);
      if (paymentElement) {
        paymentElement.clear();
      }
    },
  }));

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          // setMessage("Payment succeeded!");
          break;
        case "processing":
          // setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          // setMessage("Your payment was not successful, please try again.");
          break;
        default:
          // setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
    if (paymentIntent?.status === "succeeded") {
      paymentSuccess();
    } else {
      if (error?.type === "card_error" || error?.type === "validation_error") {
        ToastMe(error?.message, "error");
      } else {
        ToastMe("Payment Failed", "error");
      }
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form onSubmit={handleSubmit} className="add_card_form">
      <PaymentElement options={paymentElementOptions} />
      <button type="submit" className="button button-2 w-100 fs18 fw600"  disabled={!stripe || !elements || isLoading}>
        {isLoading ? "Loading..." : "Pay"}
      </button>
    </form>
  );
});

export default StripeCheckOut;
