import * as action from '../../../store/actions'
import { Trash } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form, Input } from 'antd';
import InputMask from 'react-input-mask';
import AuthService from '../../../services/auth';
import ToastMe from '../../../common/ToastMe';
import DeleteCardModal from '../../../modals/DeleteCardModal';

const ManagePaymentsMethod = (props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const userData = props?.userData.userData;
	const [cardData, setCardData] = useState([]);
	const [isSelected, setIsSelected] = useState({ payment_name: '', id: '' });
	const [removeCartModalOpen, setRemoveCartModalOpen] = useState(false);
	const [deleteCardItem, setDeleteCardItem] = useState();
	const [cardNumbers, setCardNumbers] = useState("");

	const getCard = () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`);
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		var requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

		if (userData?.customer_id) {
			fetch('https://api.stripe.com/v1/customers/' + userData?.customer_id + '/sources', requestOptions)
				.then(response => response.text())
				.then((result) => {
					let responseData = JSON.parse(result).data;
					setCardData(responseData)
					if (props?.isSelectedCard == '') {
						setIsSelected({ ...isSelected, payment_name: 'card', id: responseData[0].id })
					} else {
						setIsSelected({ ...isSelected, payment_name: 'card', id: props?.isSelectedCard })
					}
				})
				.catch(error => console.log('error', error));
		}
	}

	useEffect(() => {
		getCard();
	}, [])

	const onSubmit = (data) => {
		data['customer_id'] = userData?.customer_id
		dispatch(AuthService.addCard(data))
			.then((res) => {
				if (res) {
					form.resetFields();
					ToastMe(res.data.message, 'success')
					getCard();
				}
			})
			.catch((errors) => {
				ToastMe(errors.errorData.message, 'error')
			})
	}

	const onPaymentMethodClick = (payment_name, id) => {
		setIsSelected({ ...isSelected, payment_name: payment_name, id: id })
	}

	useEffect(() => {
		dispatch(action.setCard(isSelected));
	}, [isSelected])

	const [error, setError] = useState();
	const deleteCard = (data) => {
		console.log("data", data)
		// var last4 = data.number.substring(15, 19);
		// console.log("last4", last4)
		// deleteCardItem['card_number'] = data.number;
		// if (last4 != deleteCardItem.last4) {
		// 	ToastMe("Your card number is incorrect!")
		// } else {
		dispatch(AuthService.deleteCard(data))
			.then((res) => {
				if (res) {
					form.resetFields();
					ToastMe(res.data.message, 'success')
					getCard();
					setRemoveCartModalOpen(false);
				}
			})
			.catch((errors) => {
				setError(errors)
				ToastMe(errors.errorData.message, 'error')
			})
		// }
	}

	const removeCartModalOpenFunc = (item) => {
		setDeleteCardItem(item)
		deleteCard(item)
		// setRemoveCartModalOpen(true);
	}

	const removeCartModalClose = () => {
		form.resetFields();
		setRemoveCartModalOpen(false);
	}

	const handleCardNumber = (text) => {
		let formattedText = text.split(' ').join('');
		if (formattedText.length > 0) {
			formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' ');
		}
		setCardNumbers(formattedText)
	}

	return (
		<>
			<section className="section profile_page_wrapper gap50">
				<div className="container">
					<div className="section_title">
						<h2>Manage Payments Method</h2>
					</div>
					<div className="">
						<div className="manage_address_card mb40">
							<div className="row">
								<div className="col-xl-8">
									<div className="custom_radio_wrap">
										<div className="row gy-3">
											{
												cardData && cardData.length > 0 ?
													cardData?.map((item, i) => {
														return (
															<>
																<div className="col-lg-6" onClick={() => onPaymentMethodClick('card', item?.id)}>
																	<div className="custom_radio_group mb-4 mb-lg-0">
																		<input type="radio" className="radio_input" id={`payment_card_${i}`} name="address_radio" checked={props?.isSelectedCard == item.id ? true : false} />
																		<label htmlFor="address_1" className="radio_label">
																			<div className="address_box flex_item_sb gap-2">
																				<div className="address_box_text flex_item_ss gap-2">
																					<img src={require("../../../../assets/images/icons/icon_visa_card.svg").default} className="img-fluid" alt="" loading="lazy" />
																					<h6 className="fs18 lh-1 fw600 mb-0">
																						<p className="mt-2">**** **** **** <span className='ms-1'>{item?.last4}</span></p>
																						<p className="fs16 lh_1_5 mb-0 fw500">{item?.name} <span className="d-block fs12 fw400">{item?.exp_month}/{item?.exp_year}</span></p>
																					</h6>
																				</div>
																				<div className="address_box_action flex_item_cs">
																					<a role="button"><Trash size="24" color="#818181" onClick={() => removeCartModalOpenFunc(item)} /></a>
																				</div>
																			</div>
																		</label>
																	</div>
																</div>
															</>
														)
													}) : <h6>No Any Card</h6>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-8">
								<Form form={form} onFinish={onSubmit} autoComplete="off">
									<div className="profile_card_widget mb10">
										<h5 className="fs20 lh-1 fw600 mb20">Add New Card</h5>
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label className="fs16 fw500 lh-1 mb-3">Name</label>
													<Form.Item
														name="name"
														rules={[{ required: true, numeric: true, message: 'Please enter your card Name!' }]}
													>
														<Input placeholder="Enter Name" className="form-control" />
													</Form.Item>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label htmlFor="" className="fs16 fw500 lh-1 mb-3">Card Number</label>
													<Form.Item
														name="number"
														rules={[{ required: true, numeric: true, message: 'Please enter your card number!' }]}
													>
														<InputMask mask="9999-9999-9999-9999" value={cardNumbers} onChange={(e) => handleCardNumber(e.target.value)}>
															{(inputProps) => <Input {...inputProps}
																name='number'
																placeholder="Enter Card Number"
																className="form-control"
															/>}
														</InputMask>
													</Form.Item>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label htmlFor="" className="fs16 fw500 lh-1 mb-3">Expiry Date</label>
													<div className="row">
														<div className="col-6">
															<Form.Item
																name="exp_month"
																rules={[{ required: true, numeric: true, message: 'Please enter card exp month!' }]}
															>
																{/* <Input placeholder="Month" className="form-control" /> */}
																<InputMask mask="99">
																	{(inputProps) => <Input {...inputProps}
																		name='exp_month'
																		placeholder="Month"
																		className="form-control"
																	/>}
																</InputMask>
															</Form.Item>
														</div>
														<div className="col-6">
															<Form.Item
																name="exp_year"
																rules={[{ required: true, numeric: true, message: 'Please enter card exp year!' }]}
															>
																{/* <Input placeholder="Year" className="form-control" /> */}
																<InputMask mask="9999">
																	{(inputProps) => <Input {...inputProps}
																		placeholder="Year"
																		className="form-control"
																	/>}
																</InputMask>
															</Form.Item>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label htmlFor="" className="fs16 fw500 lh-1 mb-3">CVV</label>
													<Form.Item
														name="cvc"
														rules={[{ required: true, numeric: true, message: 'Please enter card cvv!' }]}
													>
														{/* <Input placeholder="CVV" className="form-control" /> */}
														<InputMask mask="999">
															{(inputProps) => <Input {...inputProps}
																placeholder="CVV"
																className="form-control"
															/>}
														</InputMask>
													</Form.Item>
												</div>
											</div>
										</div>
									</div>
									<div className="profile_card_widget">
										<div className="row">
											<div className="col-lg-6">
												<input type="submit" name="" class="button button-2 w-100" value="Add Card" />
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</section>
			<DeleteCardModal removeCartModalOpen={removeCartModalOpen} onClickBtn={deleteCard} removeCartModalCloseEvent={removeCartModalClose} />

		</>
	)
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
		userData: state.Auth.userData,
		isSelectedCard: state.Auth.selectedCard
	}
}
export default connect(mapStateToProps)(ManagePaymentsMethod);