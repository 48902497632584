import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

const AboutUs = () => {
    const appJourneySettings = {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 5,
        slidesToScroll: 1,
        // infinite: false,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    };
    const customerSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
    };
    return (
        <>
            {/* hero-section */}
            <section className="hero-section gap" style={{ backgroundImage: `url(${require("../../../../assets/images/background-1.png")})` }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200} data-aos-duration={300}>
                            <div className="restaurant">
                                <h1>Discover the finest selection of already discounted deals </h1>
                                <p className="mb-3">From your favourite restaurants, grocery shops, events, and more, all on this user-friendly app.</p>
                                <p>Binox Bargains connects you with fantastic discounted deals on food, groceries, events, charity, and more, enabling you to save money every step of the way.</p>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="img-restaurant">
                                <img alt="man" src={require('../../../../assets/images/photo-1.png')} />
                                {/* <div className="wilmington">
                                    <img alt="img" src={require('../../../assets/images/photo-2.jpg')} />
                                    <div>
                                        <p>Restaurant of the Month</p>
                                        <h6>The Wilmington</h6>
                                        <div>
                                            <i className="fa-solid fa-star" />
                                            <i className="fa-solid fa-star" />
                                            <i className="fa-solid fa-star" />
                                            <i className="fa-solid fa-star" />
                                            <i className="fa-regular fa-star-half-stroke" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="wilmington location-restaurant">
                                    <i className="fa-solid fa-location-dot" />
                                    <div>
                                        <h6>12 Restaurant</h6>
                                        <p>In Your city</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* works-section */}
            <section className="works-section gap no-top" id="howItWorks">
                <div className="container">
                    <div className="hading" data-aos="fade-up" data-aos-delay={200} data-aos-duration={300}>
                        <h2>How it works</h2>
                        {/* <p>Binox bargains offers you only the best bargain deals at already discounted prices directly from the sellers at your fingertips, so you don’t have to look further</p> */}
                        <p>Find the best discounted deals in 3 easy steps</p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-4 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={200} data-aos-duration={300}>
                            <div className="work-card">
                                <img alt="img" className='img-fluid' src={require('../../../../assets/images/illustration-1.svg').default} />
                                <h4><span>01</span>Find the Best Deal near you </h4>
                                <p>Easily search or browse categories to choose your preferred restaurants, grocery stores, or events from our extensive list.</p>
                                {/* <p>You can either use the search button or click on the category you wish to explore and select your preferred restaurant, grocery store, or events from the list </p> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="work-card">
                                <img alt="img" className='img-fluid' src={require('../../../../assets/images/illustration-2.svg').default} />
                                <h4><span>02</span>Select And Place Order</h4>
                                <p>Select your preferred items from our list of already discounted products, add them to your basket, and proceed to checkout with ease.</p>
                                {/* <p>Choose you preferred items from the list of already discounted products, add to basket and proceed to check out</p> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={400} data-aos-duration={500}>
                            <div className="work-card">
                                <img alt="img" className='img-fluid' src={require('../../../../assets/images/illustration-3.svg').default} />
                                <h4><span>03</span>Enjoy Your Savings </h4>
                                <p>Experience the satisfaction of quality deals and enjoy great savings with us.</p>
                                {/* <p>Enjoy your quality deals and great savings </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* app journey */}
            <section id="appJourney" className="works-section gap no-top">
                <div className="container">
                    <div className="hading" data-aos="fade-up" data-aos-delay={200} data-aos-duration={300}>
                        <h2>App Journey</h2>
                        <p className="fw-bold">Explore our app Interface</p>
                        <p>Binox Bargains offers an outstanding and stunning app interface that is simple, secure, and user-friendly. It seamlessly connects you with the best deals and discounted products, ensuring you save money throughout your experience.</p>
                        {/* <p>Binox Bargains outstanding and stunning app interface is simple, secure, and easy to use, connecting you with the best deals and discounted products, saving you money all the way</p> */}
                    </div>
                    <Slider {...appJourneySettings} className="app_journey_slider">
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide1.png')} alt="" />
                            </div>
                        </div>
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide2.png')} alt="" />
                            </div>
                        </div>
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide3.png')} alt="" />
                            </div>
                        </div>
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide4.png')} alt="" />
                            </div>
                        </div>
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide5.png')} alt="" />
                            </div>
                        </div>
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide6.png')} alt="" />
                            </div>
                        </div>
                        <div className="slide_item">
                            <div className="slider_image">
                                <img className="img-fluid" src={require('../../../../assets/images/app_journey_slide7.png')} alt="" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            {/* your-favorite-food */}
            <section className="your-favorite-food gap" style={{ backgroundImage: `url(${require("../../../../assets/images/background-1.png")})` }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5" data-aos="fade-up" data-aos-delay={200} data-aos-duration={300}>
                            <div className="food-photo-section">
                                <img alt="img" className="mobile_img img-fluid" src={require('../../../../assets/images/app_journey_slide1.png')} />
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1" data-aos="fade-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="food-content-section">
                                <h2>Enjoy bargain-priced food from your favourite restaurant delivered right to your table.</h2>
                                {/* <h2>Bargain food from your favourite restaurant to your table</h2> */}
                                {/* <p>To order discounted food from your favourite restaurant, search restaurants, select your preferred restaurant from the list, select order from the available menu, add to basket, add delivery method (eat in or takeaway) and proceed to check out.</p> */}
                                <p>To order discounted food from your favourite restaurant, follow these simple steps:</p>
                                <ul className='steps_lists'>
                                    <li>Search for restaurants.</li>
                                    <li>Select your preferred restaurant from the list..</li>
                                    <li> Choose your desired items from the available menu..</li>
                                    <li>Add them to your basket..</li>
                                    <li>Specify your preferred delivery method (eat-in or takeaway)..</li>
                                    <li> Proceed to check out and complete your order.</li>
                                </ul>
                                <Link to="/login" className="button button-2">Order Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* counters-section */}
            <section className="counters-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={200} data-aos-duration={300}>
                            <div>
                                {/* <h2>Service shows good taste.</h2> */}
                                <h2>Our service showcases excellent taste.</h2>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="count-time">
                                <h2 className="timer count-title count-number" data-to={976} data-speed={2000}>976</h2>
                                <p>Satisfied<br />
                                    Customer</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={400} data-aos-duration={500}>
                            <div className="count-time">
                                <h2 className="timer count-title count-number" data-to={12} data-speed={2000}>12</h2>
                                <p>Best<br />
                                    Restaurants</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12" data-aos="flip-up" data-aos-delay={500} data-aos-duration={600}>
                            <div className="count-time sp">
                                <h2 className="timer count-title count-number pe-3" data-to={1} data-speed={2000}>1k+</h2>
                                <p>Food<br />
                                    Delivered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* reviews-sections */}
            <section className="reviews-sections gap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12" data-aos="fade-up" data-aos-delay={200} data-aos-duration={300}>
                            <div className="reviews-content">
                                <h2>What customers have to say about us</h2>
                                <Slider {...customerSliderSettings} className='custome customers_testmonials'>
                                    <div className="item">
                                        <h4>Binox Bargains are the absolute best! I’ve taken advantage of deals which has made it easy for me to still enjoy food from my favourite restaurant at a reduced price especially now that I’ve had to cut cost due to the cost-of-living skyrocketing. It’s nice to know that I can still enjoy my favourite meal at a bargain.</h4>
                                        <div className="thomas">
                                            <img alt="girl" src={require('../../../../assets/images/photo-5.jpg')} />
                                            <div>
                                                <h6>Thomas Adamson</h6>
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <h4>Binox Bargains are the absolute best! I’ve taken advantage of deals which has made it easy for me to still enjoy food from my favourite restaurant at a reduced price especially now that I’ve had to cut cost due to the cost-of-living skyrocketing. It’s nice to know that I can still enjoy my favourite meal at a bargain.</h4>
                                        <div className="thomas">
                                            <img alt="girl" src={require('../../../../assets/images/photo-5.jpg')} />
                                            <div>
                                                <h6>Thomas Adamson</h6>
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item">
                                        <h4>"Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Mauris a diam
                                            maecenas sed enim. Egestas diam in arcu cursus euismod quis. Quam quisque id diam
                                            vel".</h4>
                                        <div className="thomas">
                                            <img alt="girl" src={require('../../../../assets/images/photo-5.jpg')} />
                                            <div>
                                                <h6>Thomas Adamson</h6>
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                            </div>
                                        </div>
                                    </div> */}
                                </Slider>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12" data-aos="fade-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="reviews-img">
                                <img alt="photo" src={require('../../../../assets/images/about-us.png')} />
                                <i className="fa-regular fa-thumbs-up" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* join-partnership */}
            <section className="join-partnership gap" style={{ backgroundColor: '#363636' }} id="contactUs">
                <div className="container">
                    <h2 className='mb-4'>Want to partner with us?</h2>
                    <div className="row gx-5">
                        <div className="col-lg-6" data-aos="flip-up" data-aos-delay={200} data-aos-duration={300}>
                            <div className="join-img">
                                <img alt="img" src={require('../../../../assets/images/partnership-1.png')} />
                                <div className="Join-courier">
                                    <h3>Join as a restaurant partner</h3>
                                    <Link to="/become-partner" state={{ flug: 'vendor' }} className="button button-2">Learn More <i className="fa-solid fa-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="join-img">
                                <img alt="img" src={require('../../../../assets/images/partnership-4.png')} />
                                <div className="Join-courier">
                                    <h3>Join as a grocery partner</h3>
                                    <Link to="/become-partner" state={{ flug: 'vendor' }} className="button button-2">Learn More <i className="fa-solid fa-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up" data-aos-delay={200} data-aos-duration={300}>
                            <div className="join-img">
                                <img alt="img" src={require('../../../../assets/images/partnership-3.png')} />
                                <div className="Join-courier">
                                    <h3>Join as an event partner</h3>
                                    <Link to="/become-partner" state={{ flug: 'vendor' }} className="button button-2">Learn More <i className="fa-solid fa-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="flip-up" data-aos-delay={300} data-aos-duration={400}>
                            <div className="join-img">
                                <img alt="img" src={require('../../../../assets/images/partnership-2.png')} />
                                <div className="Join-courier">
                                    <h3>Join as a Charity</h3>
                                    <Link to="/become-partner" state={{ flug: 'vendor' }} className="button button-2">Learn More <i className="fa-solid fa-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs