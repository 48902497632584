import { ArrowLeft2, ArrowRight2, Heart, Location } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import restaurantService from '../../../services/restruants';
import ToastMe from '../../../common/ToastMe';
import DefaultImage from '../../../../assets/images/Grocery.svg';
import PageLoader from '../../../common/PageLoader';

const Categories = (props) => {
    const searchDatas = props?.searchData;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const categoryType = state?.flug;
    const [productData, setProductData] = useState([])
    const [foodsMenuItemSelect, setFoodsMenuItemSelect] = useState(0)
    const [foodCategory, setFoodCategory] = useState();
    const [filterId, setFilterId] = useState('');
    const [favoriteData, setFavoriteData] = useState([]);
    const [loading, setLoding] = useState(true);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const sliderRef = useRef(null)

    const getRestaurant = () => {
        dispatch(restaurantService.getRestaurants(filterId))
            .then((res) => {
                setProductData(res.data)
                setLoding(false);
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const getGrocery = () => {
        dispatch(restaurantService.getGrocerys(filterId))
            .then((res) => {
                setProductData(res.data)
                setLoding(false);
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const getFoodCategory = () => {
        dispatch(restaurantService.getFoodCategorys())
            .then((res) => {
                setFoodCategory(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const getGroceryCategory = () => {
        dispatch(restaurantService.getGroceryCategorys())
            .then((res) => {
                setFoodCategory(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    //wishlist
    const wishlistAdd = (data, isFound) => {
        var data_ = {};
        data_['vendor_id'] = data._id;
        data_['is_fav'] = isFound == false ? true : false;
        dispatch(restaurantService.addToFavorite(data_))
            .then((res) => {
                // ToastMe(res.data.message);
                getFavorite();
            })
            .catch((errors) => {
                console.log(errors);
            })

    }
    const getFavorite = () => {
        dispatch(restaurantService.getFavorite())
            .then((res) => {
                setFavoriteData(res.data);
            })
            .catch((errors) => {
                console.log({ errors });
            });
    }

    const RedirectToLogin = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (categoryType === 'food') {
            if (searchDatas?.restaurant) {
                setProductData(searchDatas?.restaurant)
            } else {
                getRestaurant();
            }
            getFoodCategory();
        } else {
            if (searchDatas?.grocery) {
                setProductData(searchDatas?.grocery)
            } else {
                getGrocery();
            }
            getGroceryCategory();
        }
        getFavorite();
    }, [categoryType, filterId, searchDatas])

    const ctgSliderSetting = {
        slidesToShow: 7.4,
        arrows: false,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        afterChange: (index) => {
            setCurrentSlideIndex(index)
        },
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 6.8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 6.4,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5.4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5.4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4.1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3.1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2.8
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.1
                }
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: 1.5
                }
            },
            
        ]
    };
    const handelFoodMenuItemSelect = (menuCtgData, int) => {
        setFilterId(menuCtgData._id)
        setFoodsMenuItemSelect(int)
    }
    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    }

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    }

    const totalSlide = sliderRef.current?.props?.children
    const totalSlideCount = totalSlide?.length
    const slideToShow = ctgSliderSetting.slidesToShow
    // console.log(currentSlideIndex + slideToShow ,totalSlideCount+1 ,slideToShow)
    return (
        <>
            <PageLoader loading={loading} />
            <section className="section py-4 bg_images" style={{ backgroundImage: `url(${require("../../../../assets/images/food_menu_categories_bg.jpg")})` }}>
                <div className="container">
                    <div className="foods_menu_categories position-relative">
                    <button className={`nav_button prev ${totalSlideCount <= slideToShow || currentSlideIndex === 0 ? "d-none" : "d-flex" }`} onClick={handlePrevClick}>
                        <ArrowLeft2 size="16" color="#f5af19"  variant="Bold"/>
                        </button>
                        <button className={`nav_button next ${totalSlideCount <= slideToShow ||  currentSlideIndex >= totalSlideCount - ctgSliderSetting.slidesToShow  ?  "d-none" : "d-flex"}`} onClick={handleNextClick}>
                          <ArrowRight2 size="16" color="#f5af19"  variant="Bold"/>
                        </button>
                        <Slider {...ctgSliderSetting} ref={sliderRef} className="slide_gap30">
                            {foodCategory?.length > 0 ? foodCategory.map((menuCtgData, ind) => {
                                if (categoryType === 'food') {
                                    return (
                                        <div className={`ctg_item ${foodsMenuItemSelect == ind ? "active" : ""}`} onClick={() => handelFoodMenuItemSelect(menuCtgData, ind)} key={ind}>
                                            <a role={"button"} className="flex_item_cc">
                                                <div className="ctg_icon flex-shrink-0" >
                                                    <img src={process.env.REACT_APP_PROFILE_URL + 'foodCategory/' + menuCtgData.image} className="img-fluid" alt={menuCtgData?.name} loading="lazy"/>
                                                </div>
                                                <div className="ctg_text flex-grow-1">
                                                    <h6 className='mb-0 fs16 fw500 lh-1'>{menuCtgData?.name}</h6>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={`ctg_item ${foodsMenuItemSelect == ind ? "active" : ""}`} onClick={() => handelFoodMenuItemSelect(menuCtgData, ind)} key={ind}>
                                            <a role={"button"} className="flex_item_cc">
                                                <div className="ctg_icon flex-shrink-0" >
                                                    <img src={process.env.REACT_APP_PROFILE_URL + 'groceryCategory/' + menuCtgData.image} className="img-fluid" alt={menuCtgData?.name} loading="lazy"/>
                                                </div>
                                                <div className="ctg_text flex-grow-1">
                                                    <h6 className='mb-0 fs16 fw500 lh-1'>{menuCtgData?.name}</h6>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }
                            }) : <p>Data Not Found</p>}
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="section gap bg_lightgray">
                <div className="container">
                    <div className="section_title">
                        <h2>{categoryType === 'food' ? 'Restaurants' : 'Groceries'}</h2>
                    </div>
                    <div className="products_grids_main">
                        <div className="row">
                            {productData &&
                                productData.map((pData, i) => {
                                    const isFound = favoriteData?.some(element => {
                                        if (element._id == pData._id && element.is_fav == 1) {
                                            return true;
                                        }
                                    })
                                    if (categoryType === 'food') {
                                        return (
                                            <>
                                                <div className="col-lg-4 col-6" key={i}>
                                                    <div className="products_cards">
                                                        <div className="products_card_image mb20">
                                                            <Link role={"button"} to={"/shop/" + pData.businessName + ""} state={{ shopDetails: pData, type: 'food' }} >
                                                                <img src={pData.image ? process.env.REACT_APP_PROFILE_URL + 'vendor/' + pData.image : DefaultImage} className="img-fluid" alt={pData.businessName} loading="lazy"/>
                                                            </Link>
                                                            <div className="product_action">
                                                                {localStorage.getItem('jwt_token') ?
                                                                    <a role={"buttton"} onClick={(e) => wishlistAdd(pData, isFound)} className="wishlist_icon">
                                                                        {isFound === true ?
                                                                            <Heart size="16" color="#f0140c" variant="Bold" /> :
                                                                            <Heart size="16" color="#AAAAAA" variant="Bold" />
                                                                        }
                                                                    </a>
                                                                    :
                                                                    <a role={"buttton"} onClick={RedirectToLogin} className="wishlist_icon">
                                                                        <Heart size="16" color="#AAAAAA" variant="Bold" />
                                                                    </a>

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="products_card_text flex_item_sb gap-2">
                                                            <div className="left_part">
                                                                <h5 className='fs20'><Link to={"/shop/" + pData.businessName + ""} state={{ shopDetails: pData, type: 'food' }}>{pData.businessName}</Link></h5>
                                                                <p>{pData.description}</p>
                                                                <p className="flex_item_cs gap-2"><Location size="20px" color="#818181" /> {pData.businessAddress}</p>
                                                            </div>
                                                            <div className="right_part">
                                                                <div className="rating_badge flex_item_cc gap-1"><img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="" /><p>{pData.rating}</p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } else if (categoryType === 'grocery') {
                                        return (
                                            <>
                                                <div className="col-lg-4 col-6" key={i}>
                                                    <div className="products_cards">
                                                        <div className="products_card_image mb20">
                                                            <Link role={"button"} to={"/shop/" + pData.businessName + ""} state={{ shopDetails: pData, type: 'grocery' }}>
                                                                <img src={pData.image ? process.env.REACT_APP_PROFILE_URL + 'vendor/' + pData.image : DefaultImage} className="img-fluid" alt={pData.businessName} loading="lazy"/>
                                                            </Link>
                                                            <div className="product_action">
                                                                {localStorage.getItem('jwt_token') ?
                                                                    <a role={"buttton"} onClick={(e) => wishlistAdd(pData, isFound)} className="wishlist_icon">
                                                                        {isFound === true ?
                                                                            <Heart size="16" color="#f0140c" variant="Bold" /> :
                                                                            <Heart size="16" color="#AAAAAA" variant="Bold" />
                                                                        }
                                                                    </a>
                                                                    :
                                                                    <a role={"buttton"} onClick={RedirectToLogin} className="wishlist_icon">
                                                                        <Heart size="16" color="#AAAAAA" variant="Bold" />
                                                                    </a>

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="products_card_text flex_item_sb gap-2">
                                                            <div className="left_part">
                                                                <h5 className='fs20'><Link to={"/shop/" + pData.businessName + ""} state={{ shopDetails: pData, type: 'grocery' }}>{pData.businessName}</Link></h5>
                                                                <p>{pData.description}</p>
                                                                <p className="flex_item_cs gap-2"><Location size="20px" color="#818181" /> {pData.businessAddress}</p>
                                                            </div>
                                                            <div className="right_part">
                                                                <div className="rating_badge flex_item_cc gap-1"><img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="" /><p>{pData.rating}</p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } else {
                                        <p>Data Not Found</p>
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isVerify: state.Auth.isVerify,
        needToVerify: state.Auth.needToVerify,
        isAdministrator: state.Auth.isAdministrator,
        searchData: state.Auth.searchData
    }
};

export default connect(mapStateToProps)(Categories);