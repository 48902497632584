import * as action from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Add, Location, Minus } from "iconsax-react";
import PaymentSuccessfulModal from "../../../modals/PaymentSuccessfulModal";
import AddCardModal from "../../../modals/AddCardModal";
import PaymentFailedModal from "../../../modals/PaymentFailedModal";
import { connect, useDispatch } from "react-redux";
import restaurantService from "../../../services/restruants";
import ConfirmModel from "../../../modals/ConfirmModel";
import ToastMe from "../../../common/ToastMe";
import { Button, Rate } from "antd";
import AuthService from "../../../services/auth";
import PageLoader from "../../../common/PageLoader";
import StripePaymentModal from "../../../modals/StripePaymentModal";
const BaseUrl = process.env.REACT_APP_BASE_URL;

const Cart = (props) => {
  const userData = props.userData.userData;
  const { type } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addCardModalOpen, setAddCardModalOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentSuccessModalOpen, setPaymentSuccessModalOpen] = useState(false);
  const [paymentFailedModalOpen, setPaymentFailedModalOpen] = useState(false);
  const [cartItem, setCartItem] = useState();
  const [billDetails, setBillDetails] = useState();
  const [ConfirmModalOpen, SetConfirmModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [addressData, setAddressData] = useState();
  const [stripeToken, setStripeToken] = useState("");
  const [cardData, setCardData] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState("");
  const [isSelected, setIsSelected] = useState({ payment_name: "", id: "" });
  const [flagHomePickup, setFlagHomePickup] = useState();
  const [addressType, setAddressType] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showHideComponent, setShowHideComponent] = useState(false);
  const [shopType, setShopType] = useState();
  const [saveCard, setSaveCard] = useState(false);
  const [source, setSourceId] = useState();
  const [paymentType, setPaymentType] = useState(1);
  const [cardSelect, setCardSelect] = useState(true);
  const [loading, setLoding] = useState(true);
  const [clientSecrate, setClientSecrate] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [customerSessionClientSecret, setCustomerSessionClientSecret] =
    useState("");

  const handleToken = (token) => {
    setCardSelect(false);
    if (saveCard) {
      fetch(BaseUrl + "/create-stripe-customer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token.id,
          email: userData?.email,
          customerId: userData?.customer_id,
          name: userData?.fullname,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Customer created in Stripe:");
          getCard();
        })
        .catch((error) => {
          console.error("Error creating customer in Stripe:", error);
        });
    } else {
      setSourceId(token.id);
      setPaymentType(2);
      console.log("Payment token received:", token);
    }
  };

  useEffect(() => {
    if (userData) {
      userData?.address?.map((data) => {
        if (userData?.defaultAddress == data._id) {
          setAddressType(data.address_type);
          setAddressData(data);
          setDefaultAddress(
            data.houseNumber +
              " " +
              data.apartment_society +
              " " +
              data.street_address
          );
        }
      });
    }
  }, [userData]);

  const getCart = () => {
    dispatch(restaurantService.getCart(flagHomePickup))
      .then((res) => {
        setCartItem(res.data.cart_data);
        setBillDetails(res.data.BillDetails);
        setLoding(false);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const deleteCart = () => {
    dispatch(restaurantService.deleteCart())
      .then((res) => {
        getCart();
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  useEffect(() => {
    getCart();
    getCard();
  }, [type, flagHomePickup]);

  const handlePlus = (item, type) => {
    if (item.quantity == 1 && type === "minus") {
      setDeleteId(item._id);
      confirmModalOpen();
    } else {
      dispatch(restaurantService.addItemQuantity(item._id, type))
        .then((res) => {
          getCart();
        })
        .catch((errors) => {
          console.log({ errors });
        });
    }
  };

  const confirmModalOpen = () => {
    SetConfirmModalOpen(true);
  };

  const confirmModalClose = () => {
    SetConfirmModalOpen(false);
  };

  const deleteData = () => {
    dispatch(restaurantService.deleteItemCart(deleteId))
      .then((res) => {
        if (res.data) {
          ToastMe(res.data.message, "success");
          getCart();
        }
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const changeAddress = () => {
    navigate("/manage-address");
  };

  const onToken = (token) => setStripeToken(token.id);

  useEffect(() => {
    if (stripeToken) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`
      );
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("source", stripeToken);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        "https://api.stripe.com/v1/customers/" +
          userData?.customer_id +
          "/sources",
        requestOptions
      )
        .then((response) => {
          response.text();
          getCard();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [stripeToken]);

  const getCard = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`
    );
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (userData?.customer_id) {
      fetch(
        "https://api.stripe.com/v1/customers/" +
          userData?.customer_id +
          "/sources",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let responseData = JSON.parse(result).data;
          setCardData(responseData);
          if (props?.isSelectedCard == "") {
            setIsSelected({
              ...isSelected,
              payment_name: "card",
              id: responseData[0].id,
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const onPaymentMethodClick = (payment_name, id) => {
    setSourceId(id);
    setIsSelected({ ...isSelected, payment_name: payment_name, id: id });
  };

  const handleDirectOrderPlace = () => {
    if (flagHomePickup === undefined) {
      ToastMe("Please select delivery address or pickup", "error");
    } else if (flagHomePickup === "delivery" && userData?.address.length <= 0) {
      ToastMe("Please first add delivery address", "error");
    } else {
      setShowHideComponent(true);
    }
  };

  const paymentModalClose = () => {
    setPaymentModal(false);
    setClientSecrate("");
    setCustomerSessionClientSecret("");
    dispatch(restaurantService.orderDelete({ payment_id: paymentId }))
      .then((res) => {
        setPaymentId("");
      })
      .catch((errors) => {});
  };

  const placeOrder = () => {
    if (flagHomePickup === undefined) {
      ToastMe("Please select delivery address or pickup", "error");
    } else if (flagHomePickup === "delivery" && userData?.address.length <= 0) {
      ToastMe("Please first add delivery address", "error");
    } else if (cartItem.length > 0) {
      if (cardData.length > 0 || source != null) {
        var data = {};
        data["vendor_id"] = cartItem[0]?.vendor_id;
        data["source"] = source;
        var arr = [];
        cartItem.map((result) => {
          if (result.groceryitems_data) {
            setShopType(2);
            arr.push({
              _id: result.groceryitems_data._id,
              quantity: result.quantity,
            });
          } else {
            setShopType(1);
            arr.push({
              _id: result.fooditems_data._id,
              quantity: result.quantity,
            });
          }
        });
        data["items"] = arr;
        data["payment_type"] = paymentType;
        if (flagHomePickup === "delivery") {
          data["latitude"] = addressData?.latitude;
          data["longitude"] = addressData?.longitude;
          data["delivery_address"] = defaultAddress && defaultAddress;
        } else {
          data["is_pickup"] = true;
        }
        setButtonLoading(true);
        dispatch(restaurantService.newOrderPlace(data))
          .then((res) => {
            if (res?.data?.paymentIntent) {
              setClientSecrate(res?.data?.paymentIntent);
            }
            if (res?.data?.customerSessionClientSecret) {
              setCustomerSessionClientSecret(
                res?.data?.customerSessionClientSecret
              );
            }
            if (res?.data?.paymentId) {
              setPaymentId(res?.data?.paymentId);
            }
            setPaymentModal(true);
            dispatch(AuthService.getNotifications());
            setButtonLoading(false);
          })
          .catch((errors) => {
            setButtonLoading(false);
            if (
              errors.errorData.message ===
              "This value must be greater than or equal to 1."
            ) {
              ToastMe(
                "Your order is invalid. Please check your item quantity! Or Price must be greater than or equal to 1!"
              );
            }
            console.log({ errors });
          });
      } else {
        ToastMe("First you have add you card", "error");
      }
    } else {
      ToastMe("Your cart is empty.", "error");
    }
  };

  const removeCart = () => {
    deleteCart();
    getCart();
    navigate("/order-history", { state: { category: shopType } });
  };

  useEffect(() => {
    if (isSelected.id) {
      dispatch(action.setCard(isSelected));
    }
  }, [isSelected]);

  const paymentSuccess = () => {
    setPaymentModal(false);
    setClientSecrate("");
    setCustomerSessionClientSecret("");
    setPaymentId("");
    setPaymentSuccessModalOpen(true);
    setSourceId("");
    setPaymentType(1);
  };

  return (
    <>
      <PageLoader loading={loading} />
      <section className="section cart_page_wrapper gap50">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="menu_items_card pe-xl-5">
                <div className="section_title mb30">
                  <h2 className="fs24">Your Cart</h2>
                </div>
                <div className="menu_items_list custom-scrollbar">
                  <ul className="product_list_main">
                    {cartItem?.length > 0 ? (
                      cartItem &&
                      cartItem.map((item, i) => {
                        var data;
                        if (item.groceryitems_data) {
                          data = item.groceryitems_data;
                          data["type"] = "groceryItem/";
                        } else {
                          data = item.fooditems_data;
                          data["type"] = "foodItem/";
                        }
                        return (
                          <li key={i}>
                            <div className="product_list_item flex_item_cb gap-4">
                              <div className="product_image flex-shrink-0">
                                <img
                                  src={
                                    process.env.REACT_APP_PROFILE_URL +
                                    data?.type +
                                    data?.image
                                  }
                                  className="img-fluid w-100"
                                  alt={item.name}
                                  loading="lazy"
                                />
                              </div>
                              <div className="product_text flex_item_cb flex-grow-1">
                                <div className="left_part flex-grow-1">
                                  <h4>{data.name}</h4>
                                  <p className="price">
                                    <del className="">
                                      £{data.price?.toFixed(2)}
                                    </del>
                                    <ins>£{data.offerPrice.toFixed(2)}</ins>
                                  </p>
                                  <div className="d-block">
                                    <h6 className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2">
                                      <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 ">
                                        <Rate
                                          name="rating"
                                          color="#000"
                                          value={data.rating}
                                          precision={0.1}
                                          readOnly
                                          className="flex_item_cc gap-0"
                                        />
                                        <span>{data.rating}</span>
                                      </div>
                                    </h6>
                                  </div>
                                </div>
                                <div className="right_part flex-shrink-0">
                                  <div className="">
                                    <div className="qty_button">
                                      <a
                                        role={"button"}
                                        className="btn_qty btn_desc"
                                      >
                                        <Minus
                                          size="24"
                                          color="#333230"
                                          onClick={() =>
                                            handlePlus(item, "minus")
                                          }
                                        />
                                      </a>
                                      <input
                                        type="number"
                                        value={item.quantity}
                                        className="qty_input"
                                      />
                                      <a
                                        role={"button"}
                                        className="btn_qty btn_incrice"
                                      >
                                        <Add
                                          size="24"
                                          color="#333230"
                                          onClick={() =>
                                            handlePlus(item, "plus")
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="empty_cart_design text-center">
                        <div className="concept_image mb-4">
                          <img
                            src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/2xempty_cart_yfxml0"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h6 className="fs20 fw500 text-dark mb-2">
                          Your cart is empty
                        </h6>
                        <p className="fs14 fw500 mb-4">
                          You can go to home page to view more restaurants
                        </p>
                        {/* <button className='button button-2 w-100'>
														See restaurants near you
													</button> */}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="cart_sidebar sidebar_wrapper">
                {showHideComponent === false ? (
                  <>
                    <div className="sidebar_widget">
                      <h5 className="mb-4 fs18 fw600 lh_1_5">
                        Delivery Address
                      </h5>
                      <div className="custom_radio_wrap">
                        {billDetails?.deliveryAvailable == true ? (
                          <>
                            <div className="custom_radio_group">
                              <input
                                type="radio"
                                className="radio_input"
                                id="address_one"
                                name="address_radio"
                                onChange={() => setFlagHomePickup("delivery")}
                              />
                              <label
                                htmlFor="address_one"
                                className="radio_label"
                              >
                                <div className="address_box flex_item_sb gap-2">
                                  <div className="">
                                    <h6 className="flex_item_cs gap-2 mb-2 fs14 lh-1 fw600">
                                      <Location size="16" color="#FFBD05" />{" "}
                                      Delivery at{" "}
                                      {addressType == "0" ? "Home" : "Office"}
                                    </h6>
                                    <p className="fs14 lh_1_5 mb-0 fw400">
                                      {defaultAddress && defaultAddress}
                                    </p>
                                  </div>
                                  <a
                                    role="button"
                                    className="text_blue fw600 lh-1 fs14"
                                    onClick={() => changeAddress()}
                                  >
                                    Change
                                  </a>
                                </div>
                              </label>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="custom_radio_group">
                          <input
                            type="radio"
                            className="radio_input"
                            id="address_2"
                            name="address_radio"
                            onChange={() => setFlagHomePickup("pickup")}
                          />
                          <label htmlFor="address_2" className="radio_label">
                            <div className="address_box">
                              <p className="fs14 lh-1 mb-0">Pickup</p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar_widget">
                      <h5 className="mb-4 fs18 fw600 lh_1_5">Bill Details</h5>
                      <div className="billtotal_table_wrapper">
                        <table className="table mb30">
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>
                                £
                                {billDetails
                                  ? billDetails?.subTotal?.toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <th>Taxes</th>
                              <td>
                                £
                                {billDetails
                                  ? billDetails?.Taxes?.toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <th>Delivery Charges</th>
                              <td>
                                £
                                {billDetails
                                  ? billDetails?.deliveryCharge?.toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>TOTAL</th>
                              <td>
                                £
                                {billDetails
                                  ? billDetails?.Total?.toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                        <Button
                          loading={buttonLoading}
                          className="button button-2 w-100"
                          onClick={() => placeOrder()}
                        >
                          Place Order
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="sidebar_widget">
                    <h5 className="mb-4 fs18 fw600 lh_1_5">Select Your Card</h5>
                    <div className="custom_radio_wrap payment_method_card mb30">
                      {/* {cardData &&
                        cardData.map((item, i) => {
                          return (
                            <UserSavedCard
                              cardUserName={item.name}
                              cardId={item.id}
                              cardExpiryDate={`${item.exp_month}/${item.exp_year}`}
                              cardLast4Digit={item.last4}
                              cardBrand={item.brand}
                              isSelected={isSelected}
                              onCardClick={onPaymentMethodClick}
                              index={i}
                            />
                          );
                        })}
                      <StripeCheckout
                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                        token={handleToken}
                        email={userData?.email}
                        name={userData?.fullname}
                        panelLabel={saveCard ? "Save Card" : "Pay"}
                        allowRememberMe={saveCard}
                        style={{ color: "#fff", fontSize: 18 }}
                      >
                        <div className="">
                          <label
                            htmlFor="payment_card3"
                            className="radio_label"
                            onClick={() => setAddCardModalOpen(false)}
                          >
                            <div className="payment_card_box flex_item_cs gap-3">
                              <img
                                src={
                                  require("../../../../assets/images/icons/icon_add_card.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p className="mb-0 fs18 fw500"> Add New Card</p>
                            </div>
                          </label>
                        </div>
                      </StripeCheckout>
                      {cardSelect == true ? (
                        <>
                          <input
                            type="checkbox"
                            checked={saveCard}
                            onChange={(e) => setSaveCard(e.target.checked)}
                          />
                          <label>Save Card</label>
                        </>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <Button
                      loading={buttonLoading}
                      className="button button-2 w-100"
                      onClick={() => placeOrder()}
                    >
                      Pay
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <PaymentFailedModal
        paymentFailedModalOpen={paymentFailedModalOpen}
        setPaymentFailedModalOpen={setPaymentFailedModalOpen}
      />
      <PaymentSuccessfulModal
        billDetails={billDetails}
        paymentSuccessModalOpen={paymentSuccessModalOpen}
        setPaymentSuccessModalOpen={setPaymentSuccessModalOpen}
        onClickBtn={() => removeCart()}
      />
      <AddCardModal
        addCardModalOpen={addCardModalOpen}
        setAddCardModalOpen={setAddCardModalOpen}
      />
      <StripePaymentModal
        paymentModal={paymentModal}
        paymentModalClose={paymentModalClose}
        clientSecret={clientSecrate}
        customerSessionClientSecret={customerSessionClientSecret}
        paymentSuccess={paymentSuccess}
      />
      <ConfirmModel
        ConfirmModalOpen={ConfirmModalOpen}
        onClickBtn={deleteData}
        ConfirmModalCloseEvent={confirmModalClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    userData: state.Auth.userData,
    isSelectedCard: state.Auth.selectedCard,
  };
};
export default connect(mapStateToProps)(Cart);
